
// ------------------------------------------------
//
//  Interfaces
//
// ------------------------------------------------

import { QConnect, SessionService } from "./teachui_qwebchannel_interfaces";

export enum SystemHealth
{
  SYSTEM_OK = 0,
  SYSTEM_DEGRADED = 1,
  SYSTEM_ERROR = 2
}

export enum LocalizationQuality
{
  UNKNOWN = 0,
  GOOD = 1,
  LOST = 3
}

export enum ContingencyLeaveBehavior
{
  CONTINUE = 0,
  STOP = 1
}

export interface ErrorId
{
  component: string;
  state: string;
  ids: Array< number >;
}

export interface MapData
{
  width: number;
  height: number;
  resolution: number;

  origin_x: number;
  origin_y: number;

  data: string;
}

export interface Pose2D
{
  x: number;
  y: number;
  yaw: number;
}

export interface Vector3D
{
  x: number;
  y: number;
  z: number;
}

export interface Quaternion
{
  x: number;
  y: number;
  z: number;
  w: number;
}

export interface Transform3D
{
  sensorFrame: string;
  fixedFrame: string;

  translation: Vector3D;
  rotation: Quaternion;
}

export interface DiagnoseService
{
  systemHealth: SystemHealth;
  initProgress: number;
  errorIds: Array<ErrorId>;
  diagnosticData: any;

  systemHealthChanged: QConnect<SystemHealth>;
  initProgressChanged: QConnect<number>;
  errorIdsChanged: QConnect<void>;
  diagnosticDataChanged: QConnect<void>;

  getSystemHealth( handler: ( health: SystemHealth ) => void ): void;
  getInitProgress( handler: ( value: number ) => void ): void;
}

export interface LoggingService extends SessionService
{
  loggingActive: boolean;

  loggingActiveChanged: QConnect<boolean>;

  activate(): void;
  deactivate(): void;

  isLoggingActive( handler: ( active: boolean ) => any ): void;
}

export interface NavigationService extends SessionService
{
  map: MapData;
  currentPose: Pose2D;

  mapChanged: QConnect<void>;
  currentPoseChanged: QConnect<void>;

  localize( meanX: number, meanY: number, meanTheta: number ): void;

  getMap( handler: ( map: MapData ) => void ): void;
}

export enum JoystickMode
{
  ModeSlow   = 0,
  ModeNormal = 1,
  ModeFast   = 2
}

export interface JoystickService extends SessionService
{
  mode: JoystickMode;
  modeChanged: QConnect<JoystickMode>;

  setSpeed( vx: number, omega: number ): void;
  setMode( mode: JoystickMode ): void;
}

export interface EdgeData
{
  id: number;
  source_node: number;
  target_node: number;
  metadata: {};
}

export enum CrudStatus
{
  SUCCESS = 0,
  ERROR_OPERATION_NOT_SUPPORTED = 1000,
  ERROR_OPERATION_NOT_PERMITTED = 1001,
  ERROR_INVALID_DATA = 1002
}

export interface NavGraphServiceCrudResult
{
  status: number;
  error?: string;
  data?: any;
}

export interface UpdateEdgeData
{
  source_node?: number;
  target_node?: number;
}

export enum NodeReachedStatus
{
  NODE_NOT_REACHED = 0,
  NODE_REACHED = 1
}

export interface NodeReachedElement
{
  id: number;
  status: NodeReachedStatus;
  dist: number;
  x: number;
  y: number;
  yaw: number;
}

export interface DrivePathInfo
{
  sourceNodeId: number;
  targetNodeId: number;
  traveledDist: number;
  remainingDist: number;
  percentageDone: number;
}

export interface DrivePathError
{
  id: number;
  msg: string;
}

export enum DrivePathActionState
{
  Idle            = 0x00,
  Planning        = 0x01,
  Planned         = 0x02,
  Driving         = 0x03,
  GoalReached     = 0x20,
  GoalNotReached  = 0x21,

  Error                      = 0x100,
  InvalidRoute               = 0x101,
  TrajectoryPortNotConnected = 0x102
}

export interface DrivePathAction
{
  plannedRoute: any;
  state: DrivePathActionState;
  info: DrivePathInfo;
  error: DrivePathError;

  stateChanged: QConnect< DrivePathActionState >;
  infoChanged: QConnect< void >;
  errorChanged: QConnect< void >;
  plannedRouteChanged: QConnect< void >;

  getPlannedRoute( handler: ( result: any ) => void ): void;
  getState( handler: ( state: DrivePathActionState ) => void ): void;
  getInfo( handler: ( info: DrivePathInfo ) => void ): void;
  getError( handler: ( error: DrivePathError ) => void ): void;

  dispose(): void;

  start(): void;
  stop(): void;
  enableDrive( enable: boolean ): void;
}

export interface NodeReachedMonitor
{
  distances: Array< NodeReachedElement >;
  distancesChanged: QConnect<void>;

  stop(): void;
  getDistances( handler: ( result: Array< NodeReachedElement > ) => void ): void;
}

export interface NavGraphService extends SessionService
{
  setSpeed( vx: number, omega: number ): void;

  recordNode( attr: any, handler: ( result: NavGraphServiceCrudResult ) => void ): void;
  updateNode( id: number, attr: any, handler: ( result: NavGraphServiceCrudResult ) => void ): void;
  deleteNode( id: number, handler: ( result: NavGraphServiceCrudResult ) => void ): void;

  startRecordEdge( sourceNode: number, attr: any, handler: ( result: NavGraphServiceCrudResult ) => void ): void;
  stopRecordEdge( handler: ( result: NavGraphServiceCrudResult ) => void ): void;
  updateEdge( id: number, data: UpdateEdgeData, attr: any, handler: ( result: NavGraphServiceCrudResult ) => void ): void;
  deleteEdge( id: number, handler: ( result: NavGraphServiceCrudResult ) => void ): void;

  getGraph( handler: ( result: NavGraphServiceCrudResult ) => void ): void;

  startMonitorNodeDistances( jsonQuery: Array< {} >, handler: ( monitor: NodeReachedMonitor ) => void ): void;

  planPath( targetNode: number, handler: ( action: DrivePathAction ) => void ): void;

}

export interface MappingService extends SessionService
{
  mapping: boolean;
  mappingChanged: QConnect<boolean>;

  startMapping(): void;
  stopMapping(): void;
  isMapping( handler: ( mapping: boolean ) => void ): void;
}

export type PointData = Array< Array< number > >;

export enum VizElementType
{
  Unknown               = 0,
  Odometry              = 1,
  PointCloud2D          = 10,
  PointCloud3D          = 11,
  Boxes                 = 12,
  GridMap               = 15,
  LocalizationParticles = 20,
}

export interface SamVizElementData
{
  transform: Transform3D;
  data: any;
}

export interface SamVizElement
{
  objectName: string;
  type: VizElementType;
  active: boolean;

  data: SamVizElementData;

  dataChanged: QConnect<void>;
  isActiveChanged: QConnect<boolean>;

  getData( handler: ( data: SamVizElementData ) => void ): void;
  setActive( active: boolean ): void;
  isActive( handler: ( active: boolean ) => void ): void;
}

export type VizElementList = Array< SamVizElement >;

export interface VisualizationService extends SessionService
{
  getElements( handler: ( elements: VizElementList ) => void ): void;
  elementsChanged: QConnect<void>;
}
