import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import {
  ModalController,
  NavParams
} from '@ionic/angular';
import {subscribeToPromise} from 'rxjs/internal-compatibility';
import {Observable, Subject} from 'rxjs';
@Component({
  selector: 'app-teachui-wizard',
  templateUrl: './teachui-wizard.page.html',
  styleUrls: ['./teachui-wizard.page.scss'],
})
export class TeachuiWizardPage implements OnInit {
  modalContent: string;
  modalTitle: string;
  modalId: number;
  modalCat: string;
  modalContext: string;
  modalSequence: string;
  modalImg1: string;
  modalImg2: string;
  wizardDataResult: any;


  constructor(

    private modalController: ModalController,
    private navParams: NavParams,
    public http: HttpClient,
    public loadingController: LoadingController
  ) {
  }
  ngOnInit() {
    console.table(this.navParams);
    this.loadModalData();
  }

  // Params for Data in Wizard
  catchModalData() {
    console.log('modalContext');
    console.log(this.wizardDataResult);
    if (this.wizardDataResult) {
     // console.log('wizardDataResult given, content:');
      const wizardDataJSON = JSON.parse(JSON.stringify(this.wizardDataResult));
      this.modalContent = wizardDataJSON.body;
    }
    this.modalId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;
    this.modalCat = this.navParams.data.paramWizCat;
    this.modalImg1 = '../../assets/icon/info.png';
    this.modalImg2 = '../../assets/icon/info.png';
  }

  async closeModal() {
    const onClosedData = 'Wiz Ok!';
    await this.modalController.dismiss(onClosedData);
  }

  async prevModal() {
    this.modalId = this.navParams.data.paramID - 1;
    this.modalTitle = this.navParams.data.paramTitle;
    if (this.modalId === 122) {
      this.modalContent = 'NEUER TEXT';
    }
  }

  async nextModal() {
    this.modalId = this.navParams.data.paramID + 1;
    this.modalTitle = this.navParams.data.paramTitle;
  }

  loadModalData() {
    this.modalContent = 'ORIGINAL TEXT';
    const that = this;
    this.http.get('https://fs-lab.tech/php/wizarddb_lookup.php?context=' + this.navParams.data.paramWizContext
      + '&sequence=' + this.navParams.data.paramWizSequence).subscribe(data => {
      that.wizardDataResult = data;
      console.log(that.wizardDataResult);
      that.catchModalData();
    });
    this.modalContent = 'TOTAL NEW TEXT';
  }
}
